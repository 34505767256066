import { faPhone, faUser } from '@fortawesome/pro-light-svg-icons';

import { PHONE_NUMBERS, URLS } from '@/lib/constants';

const menuItems = [
  {
    title: 'Cars & Driving',
    slug: 'cars-and-driving',
    submenuPrimarySubtitle: 'Products & Services',
    submenuSecondarySubtitle: 'Related Articles',
    submenuSecondaryLink: '/cars-and-driving/related-articles',
    submenuSecondaryLinkText: 'View all related articles',
    subMenuItems: [
      {
        name: 'Roadside Assistance',
        link: '/cars-and-driving/roadside-assistance',
        icon: 'car',
      },
      {
        name: 'Driver Training',
        link: '/cars-and-driving/driver-training',
        icon: 'steering-wheel',
      },
      {
        name: 'Car Insurance',
        link: '/insurance-and-finance/car-insurance',
        icon: 'car-crash',
      },
      {
        name: 'Caravan & Trailer Insurance',
        link: '/insurance-and-finance/caravan-and-trailer-insurance',
        icon: 'caravan',
      },
      {
        name: 'Car Loans',
        link: '/insurance-and-finance/car-loans',
        icon: 'coins',
      },
      {
        name: 'Car Seats',
        link: '/cars-and-driving/car-seats',
        icon: 'baby',
      },
      {
        name: 'AutoServe',
        link: '/cars-and-driving/autoserve',
        icon: 'car-mechanic',
      },
      {
        name: 'Batteries',
        link: '/cars-and-driving/batteries',
        icon: 'car-battery',
      },
      {
        name: 'Fuel Saver App',
        link: '/cars-and-driving/fuel-saver-app',
        icon: 'phone-laptop',
      },
      {
        name: 'International Driving Permit',
        link: '/travel-and-experience/international-driving-permit',
        icon: 'id-card',
      },
      {
        name: 'Approved Auto Repairers',
        link: '/cars-and-driving/approved-repairers',
        icon: 'map-marker-check',
      },
      {
        name: 'Vehicle Inspections',
        link: '/cars-and-driving/vehicle-inspections',
        icon: 'clipboard-list-check',
      },
      {
        name: 'United Petroleum',
        link: '/membership/member-rewards/cars-and-driving/united-fuel-discount',
        icon: 'gas-pump',
      },
      {
        name: 'Business Roadside Cover',
        link: '/cars-and-driving/roadside-assistance/business-roadside-cover',
        icon: 'cars',
      },
    ],
    secondarySubMenuItems: [
      {
        name: 'Road trip pit stops',
        link: '/cars-and-driving/related-articles/road-trip-pit-stops',
      },
      {
        name: 'Embark on a flavourful journey',
        link: '/cars-and-driving/related-articles/embark-on-a-flavourful-journey',
      },
      {
        name: 'How supervisory drivers benefit from professional driving lessons',
        link: '/cars-and-driving/related-articles/supervisory-driver-benefits',
      },
    ],
  },
  {
    title: 'Insurance & Finance',
    slug: 'insurance-and-finance',
    submenuPrimarySubtitle: 'Products & Services',
    submenuSecondarySubtitle: 'Info & Documents',
    submenuSecondaryLink: '/insurance-and-finance/related-articles',
    submenuSecondaryLinkText: 'View all related articles',
    subMenuItems: [
      {
        name: 'Car Insurance',
        link: '/insurance-and-finance/car-insurance',
        icon: 'car-crash',
      },
      {
        name: 'Home & Contents Insurance',
        link: '/insurance-and-finance/home-and-contents-insurance',
        icon: 'home',
      },
      {
        name: 'Boat Insurance',
        link: '/insurance-and-finance/boat-insurance',
        icon: 'anchor',
      },
      {
        name: 'Caravan & Trailer Insurance',
        link: '/insurance-and-finance/caravan-and-trailer-insurance',
        icon: 'caravan',
      },
      {
        name: 'Investor Insurance',
        link: '/insurance-and-finance/investor-insurance',
        icon: 'file-invoice-dollar',
      },
      {
        name: 'Strata Insurance',
        link: '/insurance-and-finance/strata-insurance',
        icon: 'building',
      },
      {
        name: 'Travel Insurance',
        link: '/travel-and-experience/travel-insurance',
        icon: 'plane',
      },
      {
        name: 'Multi-Policy Discount',
        link: '/insurance-and-finance/multi-policy-discount',
        icon: 'garage-car',
      },
      {
        name: 'Pet Insurance',
        link: '/insurance-and-finance/pet-insurance',
        icon: 'paw',
      },
      {
        name: 'Car Loans',
        link: '/insurance-and-finance/car-loans',
        icon: 'coins',
      },
      {
        name: 'Personal Finance',
        link: '/insurance-and-finance/personal-finance',
        icon: 'wallet',
      },
      {
        name: 'Insurance Claims',
        link: '/insurance-and-finance/insurance-claims',
        icon: 'clipboard-list-check',
      },
    ],
    secondarySubMenuItems: [
      {
        name: 'Policy Changes',
        link: '/insurance-and-finance/policy-changes',
      },
      {
        name: 'Insurance Documents',
        link: '/insurance-and-finance/insurance-documents',
      },
      {
        name: 'Vulnerability & Financial Difficulty',
        link: '/insurance-and-finance/info-and-documents/vulnerability-financial-difficulty',
      },
      {
        name: 'General Insurance Information',
        link: '/insurance-and-finance/info-and-documents/general-insurance-information',
      },
      {
        name: 'Pricing Promises Review',
        link: '/insurance-and-finance/pricing-promises',
      },
    ],
  },
  {
    title: 'Travel & Experience',
    slug: 'travel-and-experience',
    submenuPrimarySubtitle: 'Products & Services',
    submenuSecondarySubtitle: 'Related Articles',
    submenuSecondaryLink: '/travel-and-experience/related-articles',
    submenuSecondaryLinkText: 'View all related articles',
    subMenuItems: [
      {
        name: 'Corporate Travel',
        link: '/travel-and-experience/corporate-travel',
        icon: 'user-tie',
      },
      {
        name: 'Cruises',
        link: '/travel-and-experience/cruises',
        icon: 'ship',
      },
      {
        name: 'Holiday Here This Year',
        link: '/travel-and-experience/holiday-here-this-year',
        icon: 'map-marked-alt',
      },
      {
        name: 'Hot Deals',
        link: '/travel-and-experience/hot-deals',
        icon: 'fire-alt',
      },
      {
        name: 'International Driving Permit',
        link: '/travel-and-experience/international-driving-permit',
        icon: 'id-card',
      },
      {
        name: 'Travel Information Sessions',
        link: '/travel-and-experience/travel-information-sessions',
        icon: 'info-circle',
      },
      {
        name: 'Travel Insurance',
        link: '/travel-and-experience/travel-insurance',
        icon: 'plane',
      },
      {
        name: 'Travel Services',
        link: '/travel-and-experience/travel-services',
        icon: 'suitcase',
      },
    ],
    secondarySubMenuItems: [
      {
        name: 'Driving abroad with confidence',
        link: '/travel-and-experience/related-articles/driving-abroad-with-confidence-discover-the-benefits-of-an-idp-when-travelling',
      },
      {
        name: 'Your top Tassie parks',
        link: '/travel-and-experience/related-articles/your-top-tassie-parks',
      },
      {
        name: 'Hidden gems',
        link: '/travel-and-experience/related-articles/hidden-gems',
      },
    ],
  },
  {
    title: 'Community',
    slug: 'community',
    submenuPrimarySubtitle: 'Programs & Initiatives',
    submenuSecondarySubtitle: 'Related Articles',
    submenuSecondaryLink: '/community/related-articles',
    submenuSecondaryLinkText: 'View all related articles',
    subMenuItems: [
      {
        name: 'Community Education',
        link: '/community/community-education',
        icon: 'graduation-cap',
      },
      {
        name: 'Community Support',
        link: '/community/community-support',
        icon: 'hands-helping',
      },
      {
        name: 'Advocating Change',
        link: '/community/advocating-change',
        icon: 'megaphone',
      },
      {
        name: 'Road Safety Week',
        link: '/community/advocating-change/road-safety-week',
        icon: 'road',
      },
      {
        name: 'News & Media',
        link: '/community/news-and-media',
        icon: 'newspaper',
      },
      {
        name: 'Our Learning Centre',
        link: '/community/our-learning-centre',
        icon: 'books',
      },
      {
        name: 'Youth Road Safety',
        link: '/community/more-than-a-statistic',
        icon: 'car-tilt',
      },
      {
        name: '100 Years',
        link: '/community/100-years',
        icon: 'infinity',
      },
    ],
    secondarySubMenuItems: [
      {
        name: 'Driving road safety forward with automated traffic cameras',
        link: '/community/related-articles/driving-road-safety-forward-with-automated-traffic-cameras',
      },
      {
        name: 'Tasmania leads the nation in electric car purchases',
        link: '/community/related-articles/tasmania-leads-the-nation-in-electric-car-purchases',
      },
      {
        name: 'Heartening lowering of road deaths but more needs to be done',
        link: '/community/related-articles/heartening-lowering-of-road-deaths-but-more-needs-to-be-done',
      },
    ],
  },
  {
    title: 'Membership',
    slug: 'membership',
    submenuPrimarySubtitle: 'Information & Benefits',
    submenuSecondarySubtitle: 'Related Articles',
    submenuSecondaryLink: '/membership/related-articles',
    submenuSecondaryLinkText: 'View all related articles',
    subMenuItems: [
      {
        name: 'Current Promotions',
        link: '/membership/current-promotions',
        icon: 'star-exclamation',
      },
      {
        name: 'Member Rewards',
        link: '/membership/member-rewards',
        icon: 'gifts',
      },
      {
        name: 'Become a Member',
        link: '/membership/become-a-member',
        icon: 'user-check',
      },
      {
        name: 'Become a Partner',
        link: '/membership/become-a-partner',
        icon: 'handshake',
      },
      {
        name: 'Journeys',
        link: '/membership/journeys',
        icon: 'book-open',
      },
      {
        name: 'Manage Your Membership',
        link: '/membership/manage-your-membership',
        icon: 'user-cog',
      },
      {
        name: 'Gift Vouchers',
        link: '/membership/gift-vouchers',
        icon: 'gift-card',
      },
      {
        name: 'Annual General Meeting',
        link: '/about-us/agm',
        icon: 'users-class',
      },
    ],
    secondarySubMenuItems: [
      {
        name: 'Gold class perks',
        link: '/membership/related-articles/gold-class-perks',
      },
      {
        name: 'Time to spring clean your home?',
        link: '/membership/related-articles/time-to-spring-clean-your-home',
      },
      {
        name: 'Thinking about switching your health cover?',
        link: '/membership/related-articles/thinking-about-switching-your-health-cover',
      },
    ],
  },
];

const headerUtilityItems = [
  {
    name: 'FAQs',
    link: URLS.RACT_HELP,
  },
  {
    name: 'Contact us',
    link: '/getting-in-touch',
  },
  {
    name: 'About us',
    link: '/about-us',
  },
  {
    name: 'Help & support',
    link: '/insurance-and-finance/help-support',
  },
];

const headerControlDataItems = [
  {
    title: 'Call us',
    link: PHONE_NUMBERS.RACT_GENERAL_ENQUIRIES.LINK,
    linkIcon: 'icon-link-call',
    icon: faPhone,
    text: 'Call RACT',
  },
  {
    title: 'Member hub',
    link: URLS.RACT_HUB,
    linkIcon: 'icon-link-login',
    icon: faUser,
    text: 'Login to member hub',
  },
];

export { menuItems, headerUtilityItems, headerControlDataItems };
