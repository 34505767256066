/* eslint-disable no-param-reassign */
import { ReactElement, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import clsx from 'clsx';

import { HeaderControls } from '@/components/header/header-controls';
import { HeaderUtilities } from '@/components/header/header-utilities';
import { Navigation } from '@/components/header/navigation';
import { HUNDRED_YEAR_TAKEOVER } from '@/lib/constants';

const Header = (): ReactElement => {
  const { events } = useRouter();
  const [isMobileMenuActive, setIsMobileMenuActive] = useState(false);

  useEffect(() => {
    const handleRouteChange = () => {
      if (isMobileMenuActive) {
        setIsMobileMenuActive(false);
        const mainContent = document.querySelector('main');
        const bodyContent = document.querySelector('body');
        const footer = document.querySelector('footer');
        mainContent?.classList.remove('drawer-open');
        footer?.classList.remove('drawer-open-footer');
        bodyContent?.classList.remove('prevent-scroll');
      }
    };

    // Close the mobile menu if it is open when pages change
    events.on('routeChangeStart', handleRouteChange);
    return () => {
      events.off('routeChangeStart', handleRouteChange);
    };
  });

  return (
    <header data-datocms-noindex className={clsx(HUNDRED_YEAR_TAKEOVER ? 'hundred-year-theme' : undefined)}>
      <div id="header" className={clsx(HUNDRED_YEAR_TAKEOVER ? 'hundred-year-theme' : undefined)}>
        <div className="row">
          <div className="component ract-menu responsive-header col-12">
            <div className="component-content">
              <HeaderControls isMobileMenuActive={isMobileMenuActive} setIsMobileMenuActive={setIsMobileMenuActive} />
              <div
                id="menu"
                className={clsx('header-content menu-collapse js-menu', { active: isMobileMenuActive })}
                aria-hidden={isMobileMenuActive ? 'false' : 'true'}
              >
                <Navigation />
                <HeaderUtilities />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export { Header };
