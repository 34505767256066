import { isEmpty } from 'lodash';

import { captureException } from '@/utils/sentry';
import { CATEGORY_SLUGS, SLUGS } from '@/lib/constants';
import { getPageUrlString } from '@/lib/datocms/utils/get-page-url';
import { notify } from '@/utils/notify';
import type { ModelCard, ModelLink } from '@/lib/types';

const getURL = (model?: ModelLink | ModelCard): string | null => {
  switch (model?.type) {
    case 'article': {
      const categorySlug = model.categoryPage?.slug;
      const articleSlug = model.slug;
      if (Object.values(CATEGORY_SLUGS).includes(categorySlug)) {
        return `/${categorySlug}/${SLUGS.RELATED_ARTICLES}/${articleSlug}`;
      }
      return getPageUrlString(model);
    }

    case 'external_link':
      return model.uri;

    case 'journey':
      return `/${CATEGORY_SLUGS.MEMBERSHIP}/${SLUGS.JOURNEYS}/${model.tag.slug}/${model.slug}`;

    case 'tag':
      return `/${CATEGORY_SLUGS.MEMBERSHIP}/${SLUGS.JOURNEYS}/${model.slug}`;

    case 'reward':
      return `/${CATEGORY_SLUGS.MEMBERSHIP}/${SLUGS.MEMBER_REWARDS}/${model.categoryPage?.slug}/${model.slug}`;

    case 'home_page':
      return '/';

    case 'journey_landing_page':
      return `/${CATEGORY_SLUGS.MEMBERSHIP}/${SLUGS.JOURNEYS}`;

    case 'page':
      return getPageUrlString(model);

    case 'file':
      return model.file.url;

    default:
      return null;
  }
};

const getModelURL = (model?: ModelLink | ModelCard, anchor?: string): string => {
  const url = getURL(model);
  const fullUrl = `${isEmpty(url) ? '' : url}${isEmpty(anchor) ? '' : `#${anchor}`}`;
  if (isEmpty(fullUrl)) {
    notify('A link on this page is missing a url or anchor. Please check all links.', { id: 'model-url' });
    captureException(new Error('Unhandled model URL'), { extra: { model, anchor } });
    return '#';
  }
  return fullUrl;
};

export { getModelURL };
