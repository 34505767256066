import { toast } from 'react-hot-toast';

const isNotifyEnabled = process.env.NEXT_PUBLIC_DATOCMS_PREVIEW_ENABLED === 'true';

type Options = {
  id?: string;
};

const notify = (message: string, options: Options | undefined): void => {
  if (isNotifyEnabled) {
    toast.error(message, {
      duration: Infinity,
      style: {
        border: '1px solid red',
        padding: '10px',
        color: 'red',
        fontSize: '14px',
        marginTop: '10px',
        marginLeft: '10px',
      },
      ...options,
    });
  }
};

export { notify };
